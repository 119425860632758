/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'credit-card-2-back': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 5.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5z"/><path pid="1" d="M2 2a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zm13 2v5H1V4a1 1 0 011-1h12a1 1 0 011 1m-1 9H2a1 1 0 01-1-1v-1h14v1a1 1 0 01-1 1"/>',
    },
});
